<template>
    <div>
        <div class="image-cropper__content">
            <div class="image-cropper__cropper">
                <croppa ref="cropper" class="image-cropper__croppa"
                    auto-sizing
                    placeholder="Перетащите изображение сюда, либо нажмите"
                    placeholder-color="#000"
                    :placeholder-font-size="16"
                    canvas-color="transparent"
                    :show-remove-button="true"
                    remove-button-color="black"
                    :initial-image="croppedImageOrigin"
                    :show-loading="true"
                    :disable-scroll-to-zoom="false"
                    :loading-size="50"
                    @initial-image-loaded="setData">
                </croppa > 
            </div>
            <div class="actions">
                <a href="#" title="повернуть вправо" role="button" @click.prevent="rotate()">
                    <rotate-right-icon />
                </a>
                <a href="#" title="повернуть влево" role="button" @click.prevent="rotate(-1)">
                    <rotate-left-icon />
                </a>
                <a
                    ref="flipX"
                    href="#"
                    title="развернуть по горизонтали" 
                    role="button"
                    @click.prevent="flipX"
                >
                    <flip-horizontal-icon />
                </a>
                <a
                    ref="flipY"
                    href="#"
                    title="развернуть по вертикали" 
                    role="button"
                    @click.prevent="flipY"
                >
                    <flip-vertical-icon />
                </a>
                <a href="#" title="сбросить настройки" role="button" @click.prevent="reset"> 
                    <reload-icon />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import 'vue-croppa/dist/vue-croppa.css'
import Croppa from 'vue-croppa'
import VueCropper from 'vue-cropperjs'

import { mapState } from 'vuex'
import store from '../store'
import * as mutations from '../store/types/mutations-types'

import RotateRightIcon from 'vue-material-design-icons/RotateRight.vue';
import RotateLeftIcon from 'vue-material-design-icons/RotateLeft.vue';
import FlipHorizontalIcon from 'vue-material-design-icons/FlipHorizontal.vue';
import FlipVerticalIcon from 'vue-material-design-icons/FlipVertical.vue';
import ReloadIcon from 'vue-material-design-icons/Reload.vue';
import FileUploadOutlineIcon from 'vue-material-design-icons/FileUploadOutline.vue';

export default {
    name: 'image-cropper',
    store,
    components: {
        croppa: Croppa.component,
        VueCropper,
        RotateRightIcon,
        RotateLeftIcon,
        FlipHorizontalIcon,
        FlipVerticalIcon,
        ReloadIcon,
        FileUploadOutlineIcon,
    },
    computed: {
        croppedImage: {
            get() { return this.$store.state.cropped.image },
            set(value) { this.$store.commit(mutations.UPDATE_CROPPED_IMAGE, value) },
        },
        croppedImageOrigin: {
            get() { return this.$store.state.cropped.imageOrigin },
            set(value) { this.$store.commit(mutations.UPDATE_CROPPED_IMAGE_ORIGIN, value) },
        },
        croppedImageData: {
            get() { return this.$store.state.cropped.imageData },
            set(value) { this.$store.commit(mutations.UPDATE_CROPPED_IMAGE_DATA, value) },
        }
    },
    methods: {
        cropImage() {
            this.croppedImageOrigin = this.$refs.cropper.originalImage.src;
            return this.$refs.cropper.generateDataUrl();
        },
        flipX() {
            this.$refs.cropper.flipX();
        },
        flipY() {
            this.$refs.cropper.flipY();
        },
        updateCroppedImg() {
            this.croppedImage = this.$refs.cropper.generateDataUrl();
        },
        getData() {
            this.croppedImageData = JSON.stringify(this.$refs.cropper.getMetadata(), null, 4);
            
            return this.$refs.cropper.getMetadata()
        },
        reset() {
            this.$refs.cropper.refresh();
        },
        rotate(dir) {
            this.$refs.cropper.rotate(dir);
        },
        setData() {
            if (!this.croppedImageData) return;

            this.$refs.cropper.applyMetadata(JSON.parse(this.croppedImageData));
        },
        setImage(e) {
            const file = e.target.files[0];

            if (file.type.indexOf('image/') === -1) {
                alert('Пожалуйста выберите картинку');
                return;
            }

            if (typeof FileReader === 'function') {
                const reader = new FileReader();

                reader.onload = (event) => {
                    this.croppedImageOrigin = event.target.result;
                    this.$refs.cropper.replace(event.target.result);
                };

                reader.readAsDataURL(file);
            } else {
                alert('К сожалению, FileReader API не поддерживается');
            }
        },
        showFileChooser() {
            this.$refs.input.click();
        },
    },
};
</script>

<style>
@import '../../../assets/css/variables.css';

.croppa-container {
   background-color: transparent;
   border: 2px solid var(--main-color);/*grey */
 }
 
 .croppa-container:hover {
   opacity: 1;
   background-color: transparent;
 }

.image-cropper__content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
}

.image-cropper__cropper{
    width: 100%;
    height: 350px;
}

.image-cropper__croppa {
    width: 100%;
    height: 100%;
}

@media (max-width: 1440px) {
    .image-cropper__cropper {
        height: 333px;
    }
}

@media (max-width: 1200px) {
    .image-cropper__cropper {
        height: 274px;
    }
}

@media (max-width: 990px) {
    .image-cropper__cropper {
        height: 210px;
    }
}

@media (max-width: 768px) {
    .image-cropper__cropper {
        height: 155px;
    }
}

@media (max-width: 600px) {
    .image-cropper__cropper {
        height: 148px;
    }
}

@media (max-width: 414px) {
    .image-cropper__cropper {
        height: 100px;
    }
}

.actions {
    margin-top: 1rem;
}

.actions a {
    display: inline-block;
    padding: 5px 15px;
    background:var(--main-color); /*#0062cc;*/
    color: var(--main-secondary-color);/*white */
    text-decoration: none;
    border-radius: 3px;
    margin-right: 1rem;
    margin-bottom: 1rem;
}
.actions a:hover {
    /*background:color-mod(var(--main-color) blackness(+10%));*/ /*#0062cc;*/
}
</style>
