<template>
  <init-modal
    class="product-modal-fullscreen"
    :is-show="isShow"
    @close="closeModal"
  >
    <div class="product-modal-fullscreen__content">
      <app-product-slider v-if="productImages.length > 0" :images="productImages" :need-reset-component="isShow" />
      <div class="product-modal-fullscreen__body">
        <div class="product-modal-fullscreen__body-product-desc">
          <h2 class="product-modal-fullscreen__body-title">
            {{ modalTitle }}
          </h2>
          <p class="product-modal-fullscreen__body-desc">
            {{ productBody || productDescription }}
          </p>
        </div>
        <div v-if="productVariantsListLength > 0 && multipleOptionsList > 1" class="variants-list">
          <p class="variants-list__title">Выберите размер</p>
          <div class="variants-list__container">
            <button
                v-for="(v, index) in productVariants"
                :key="index"
                class="variants-list__btn"
                :class="{ 'variants-list__btn--active': activeVariant === index }"
                @click="activateToggleProduct(index, v.parameters[0].key, productId)"
            >
              <span class="variants-list__btn-title">{{ v.title }}</span>
              <div class="variants-list__btn-wrapper">
                <span class="variants-list__btn-weight">{{ v.weight }}г</span>
                <span class="variants-list__btn-price">{{ getVariantPrice(v) }}₽</span>
              </div>
            </button>
          </div>
        </div>
        <p v-if="hasGroupModifiers" class="variants-list__title">Добавки</p>
        <div
            v-if="productBuyable"
            class="product-modal-fullscreen__additives"
            v-for="(groupModifier, index) in productVariants[activePriceIndex].groupModifiers"
            :key="index"
        >
          <p class="groupModifier-list__title">{{ groupModifier.title }}</p>
          <div v-if="groupModifier.maxAmount === 1" class="modifier-list__container">
            <button
                v-for="(modifier, modifierIndex) in groupModifier.modifiers"
                :key="modifierIndex"
                :class="{ 'modifier-list__btn--active': radioModifiersIdList[groupModifier.title] === modifier.id }"
                class="modifier-list__btn"
                @click="selectRadioModifiersId(modifier.id, groupModifier.title)"
            >
              <span class="modifier-list__btn-title">{{ modifier.title }}</span>
              <span class="modifier-list__btn-price">+{{ modifier.price }}₽</span>
            </button>
          </div>

          <div v-if="groupModifier.maxAmount > 1" class="modifier-list__container">
            <button
                v-for="(modifier, modifierIndex) in groupModifier.modifiers"
                :key="modifierIndex"
                :class="{ 'modifier-list__btn--active': checkboxModifiersIdList.includes(modifier.id) }"
                class="modifier-list__btn"
                @click="selectCheckboxModifiersId(modifier.id)"
            >
              <span class="modifier-list__btn-title">{{ modifier.title }}</span>
              <span class="modifier-list__btn-price">+{{ modifier.price }}₽</span>
            </button>
          </div>
        </div>
        <div
            v-if="isUsingShopLogic"
            class="product-modal-fullscreen__button"
            :style="{ bottom: totalSum > 0 ? '88px' : '30px' }"
        >
          <app-button
            v-if="productBuyable && (amountProduct !== 0)"
            @click="addToBasket(productId)"
          >
            Положить в корзину <span class="product-modal-fullscreen__button-text">{{ resultSum }}₽</span>
          </app-button>
          <app-button
            v-else
            is-disabled
          >
            Нет в наличии
          </app-button>
        </div>
      </div>
    </div>
  </init-modal>
</template>

<script>
import store from '@store'
import { mapMutations, mapState } from 'vuex'
import * as actions from '@store/types/actions-types'
import * as mutations from '@store/types/mutations-types'

import appRadio from '@ui/app-radio/appRadio.vue'
import appButton from '@ui/app-button/appButton.vue'
import appAccordion from '@components/appAccordion.vue'
import appCheckbox from '@ui/app-checkbox/appCheckbox.vue'
import appRadioTape from '@ui/app-radio-tape/appRadioTape.vue'
import initModal from '@ui/app-modals/init-modal/initModal.vue'
import AppProductSlider from '@ui/app-product-slider/appProductSlider.vue'

export default {
  name: 'ProductModalFullscreen',
  components: {
    appRadio,
    initModal,
    appButton,
    appCheckbox,
    appAccordion,
    appRadioTape,
    AppProductSlider,
  },
  props: {
    isShow: {
      type: Boolean,
      required: true,
      default: false,
    },
    totalSum: {
      type: Number,
      required: true
    }
  },
  emits: ['close'],
  data: () => ({
    currentSlideIndex: 0,
    image: [],
    checkboxModifiersIdList: [],
    radioModifiersIdList: {},
    resultModifiersIdList: [],
    selectedModifiersSum: 0,
    resetModifiers: false,
    activeVariant: null,
  }),
  computed: {
    ...mapState({
      isUsingShopLogic: state => state.isUsingShopLogic,
      activePriceIndex: state => state.activePriceIndex,
      productId: state => state.product.id,
      productTitle: state => state.product.title,
      productBody: state => state.product.body,
      productDescription: state => state.product.description,
      productImages: state => state.product.images,
      productBuyable: state => state.product.isBuyable,
      productVariants: state => state.product.variants,
      productVariantsList: state => state.productVariantsList,
      amountProduct: state => state.amountProduct,
      screenWidth: state => state.screenWidth,
      SCREENS: state => state.screenSizeList,
      selectedParametersIndexMap: state => state.selectedParametersIndexMap,
      paidByBonuses: state => state.orderDetails.paidByBonuses,
      selectedPickupPoint: state => state.selectPickupPoint,
      pricesByPickupPoints: state => state.pricesByPickupPoints,
      initProducts: state => state.initProducts,
    }),
    resultSum() {
      if (this.selectedPickupPoint && this.initProducts[this.productId - 1] && Object.keys(this.pricesByPickupPoints).length > 0) {
        if (!this.pricesByPickupPoints[this.productId][this.selectedPickupPoint.option.apiId]) {
          return this.productVariants[this.activePriceIndex].price + this.selectedModifiersSum
        }
        const variantId = this.initProducts[this.productId - 1].variants[this.activePriceIndex].apiId

        return this.pricesByPickupPoints[variantId][this.selectedPickupPoint.option.apiId].price + this.selectedModifiersSum
      }

      if (!this.selectedPickupPoint || !Object.keys(this.pricesByPickupPoints).length) {
        return this.productVariants[this.activePriceIndex].price + this.selectedModifiersSum
      }
    },
    modalTitle() {
      const variants = Object.values(this.productVariantsList)
      const isSingleVariantOfRadioTape = variants.length === 1 && variants[0].size === 1

      if (!isSingleVariantOfRadioTape) {
        return this.productTitle
      }

      if (isSingleVariantOfRadioTape) {
        const singleRadioList = Object.values(this.productVariantsList)[0]
        const singleRadioName = [...singleRadioList][0]

        return `${this.productTitle} ${singleRadioName && `(${singleRadioName})`}`
      }
    },
    productVariantsListLength() {
      return Object.keys(this.productVariantsList).length
    },
    multipleOptionsList() {
      return Object.values(this.productVariantsList)[0].size
    },
    hasGroupModifiers() {
      return this.productVariants[this.activePriceIndex]?.groupModifiers?.length > 0
    },
  },
  watch: {
    resultModifiersIdList() {
      const activeVariant = this.productVariants[this.activePriceIndex]

      if (activeVariant && activeVariant.groupModifiers) {
        const selectedModifiersList = activeVariant.groupModifiers.flatMap(
            groupModifier => groupModifier.modifiers.filter(
                modifier => this.resultModifiersIdList.includes(modifier.id),
            ),
        )

        this.selectedModifiersSum = selectedModifiersList.reduce((acc, item) => acc + item.price, 0)

        return this.selectedModifiersSum
      }
      return []
    },
    isShow(newVal) {
      if (newVal && this.productVariants.length > 0) {
        this.activateToggleProduct(0, this.productVariants[0].parameters[0].key, this.productId)
      }
    },
  },
  methods: {
    ...mapMutations({
      setActiveToggle: mutations.SET_ACTIVE_TOGGLE,
      changeToggleParameter: mutations.CHANGE_TOGGLE_PARAMETER,
      selectGroupModifier: mutations.SELECT_GROUP_MODIFIER,
      resetVariantIndex: mutations.RESET_ACTIVE_PRICE_VARIANT,
      clearDraftProduct: mutations.CLEAR_DRAFT_PRODUCT,
      clearProduct: mutations.CLEAR_PRODUCT,
      clearProductVariantsList: mutations.CLEAR_PRODUCT_VARIANTS_LIST,
      resetImgPath: mutations.SET_IMAGE_PATH,
      resetImagePosition: mutations.RESET_IMAGE_POSITION,
    }),
    selectCheckboxModifiersId(id) {
      const index = this.checkboxModifiersIdList.indexOf(id)

      index > -1
        ? this.checkboxModifiersIdList.splice(index, 1)
        : this.checkboxModifiersIdList.push(id)

      this.changeResultModifiersArray()
    },
    selectRadioModifiersId(id, key) {
      if (this.radioModifiersIdList[key] === id) {
        delete this.radioModifiersIdList[key]
      } else {
        this.radioModifiersIdList[key] = +id
      }
      this.changeResultModifiersArray()
    },
    changeResultModifiersArray() {
      const radioListValues = Object.values(this.radioModifiersIdList)
      this.resultModifiersIdList = [...this.checkboxModifiersIdList, ...radioListValues]
    },
    resetModalState() {
      this.currentSlideIndex = 0
      this.image = []
      this.checkboxModifiersIdList = []
      this.radioModifiersIdList = {}
      this.resultModifiersIdList = []
      this.selectedModifiersSum = 0
      this.resetVariantIndex()
      this.clearDraftProduct()
      this.resetImgPath('')
      this.resetImagePosition()
      this.clearProduct()
      this.clearProductVariantsList()
    },
    closeModal() {
      this.$emit('close')
      this.resetModalState()
    },
    activateToggleProduct(index, key, productId) {
      this.activeVariant = index
      this.setActiveToggle([key, index])
      this.changeToggleParameter(productId)
      this.$store.commit(mutations.RESET_SELECTED_MODIFIERS)
    },
    addToBasket(productId) {
      store.dispatch(
        actions.ADD_TO_BASKET,
        { currentProductId: productId, currentModifierIds: this.resultModifiersIdList },
      )
      this.checkboxModifiersIdList = []
      this.radioModifiersIdList = {}
      this.resultModifiersIdList = []
      this.selectedModifiersSum = 0
      this.activeVariant =  null
    },
    getVariantPrice(variant) {
      if (this.selectedPickupPoint && Object.keys(this.pricesByPickupPoints).length > 0) {
        if (!this.pricesByPickupPoints[variant.apiId][this.selectedPickupPoint.option.apiId]) {
          return variant.price
        }

        return this.pricesByPickupPoints[variant.apiId][this.selectedPickupPoint.option.apiId].price
      } else {
        return variant.price
      }
    }
  },
}
</script>
