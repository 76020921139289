<template>
    <div @click="toggleSwitch" :class="{ 'on': isOn }" class="switch"></div>
</template>

<script>
export default {
    name: 'appSwitch',
    emits: [
        'on-toggle'
    ],
    data() {
        return {
            isOn: false
        }
    },
    methods: {
        toggleSwitch() {
            this.isOn = !this.isOn
            this.$emit('on-toggle', this.isOn)
        }
    }
}
</script>
