import md5 from 'js-md5'

import yml from '@config/parameters.yml'
import { state } from '../store'

const CLEANER_KEY = 'qeep-cleaner'
const STATE_KEY = 'vuex'
const VERSION_KEY = 'qeep-cache-version'

const clearStateByChangedSchema = () => {
    const storeHash = md5(JSON.stringify(state))

    const needReload = localStorage.getItem(CLEANER_KEY) && localStorage.getItem(CLEANER_KEY) !== storeHash

    if (needReload) {
        localStorage.removeItem(STATE_KEY)
        localStorage.setItem(CLEANER_KEY, storeHash)
        location.reload()
    }
}

const clearStateByChangedCacheVersion = () => {
    const clearStateAndSaveCacheVersion = () => {
        localStorage.removeItem(STATE_KEY)
        localStorage.setItem(VERSION_KEY, yml.parameters['app.cache_version'])
    }

    const cacheVersion = localStorage.getItem(VERSION_KEY)

    if (!cacheVersion || cacheVersion !== yml.parameters['app.cache_version']) {
        clearStateAndSaveCacheVersion()
        if (cacheVersion) {
            window.location.reload()
        }
    }
}

const localStorageCleaner = () => {
    clearStateByChangedCacheVersion()
    clearStateByChangedSchema()
}

export default localStorageCleaner
