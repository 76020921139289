<template>
  <init-modal
      class="cookies-modal"
      @close="closeModal"
      :is-show="isShow"
      :is-bottom-position="true"
  >
    <p class="cookies-modal__text">
      <strong>Мы используем cookies.</strong>
    </p>
    <p class="cookies-modal__text">
      Они необходимы для работы сайта. Вы можете изменить настройки в вашем браузере.
      Подробнее — в <a class="cookies-modal__link" href="/agreement">Политике обработки информации</a>.
    </p>
    <div class="cookies-modal__container-btn">
      <app-button @click="acceptCookies">Хорошо</app-button>
    </div>
  </init-modal>
</template>

<script>
import initModal from '@ui/app-modals/init-modal/initModal.vue'
import appButton from '@ui/app-button/appButton.vue'
import Cookies from 'js-cookie'

export default {
  name: 'cookiesModal',
  components: { initModal, appButton },
  props: {
    isShow: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
    acceptCookies() {
      Cookies.set('cookieConsent', 'true')
      this.closeModal()
    },
  },
}
</script>
