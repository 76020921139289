<template>
  <init-modal
    class="bonus-program-modal"
    @close="closeModal"
    :is-show="isShow"
    :is-bottom-position="true"
  >
    <bonus-program
      :show-full-description="true"
      :short-description-bonus="shortDescriptionBonus"
      :full-description-bonus="fullDescriptionBonus"
    />
  </init-modal>
</template>

<script>
import initModal from '@ui/app-modals/init-modal/initModal.vue'
import bonusProgram from '@components/bonus-program/bonusProgram.vue'
import { mapState } from 'vuex'

export default {
  name: 'bonusProgramModal',
  components: { initModal, bonusProgram },
  props: {
    isShow: {
      type: Boolean,
      required: true,
      default: false,
    },
    fullDescriptionBonus: {
      type: String,
      required: true,
    },
    shortDescriptionBonus: {
      type: String,
      required: true,
    },
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
  },
  computed: {
    ...mapState({
      isAuthenticated: state => state.customer.isAuthenticated,
      bonusSystem: state => state.bonusSystem,
      customer: state => state.customer,
    }),
  }
}

</script>
