import md5 from 'js-md5'

import { state } from '../store'

const CLEANER_KEY = 'qeep-cleaner'
const STATE_KEY = 'vuex'

const clearStateByChangedSchema = () => {
    const storeHash = md5(JSON.stringify(state))

    const needReload = localStorage.getItem(CLEANER_KEY) && localStorage.getItem(CLEANER_KEY) !== storeHash

    if (needReload) {
        localStorage.removeItem(STATE_KEY)
        localStorage.setItem(CLEANER_KEY, storeHash)
        location.reload()
    }
}

export default () => clearStateByChangedSchema()
