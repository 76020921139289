/* eslint space-before-function-paren: ["error", "always"] */
/* eslint no-param-reassign: ["error", { "props": false }] */
import Vue from 'vue'
import Toasted from 'vue-toasted'

import showToast from '../utils/showToast'
import { formatVariant } from '../utils/variants'
import * as mutations from './types/mutations-types'
import * as actions from './types/actions-types'

import {
    getDefaultCity,
    fetchOptionsFilters,
    fetchPriceRange,
    fetchQuestions,
    fetchCities,
    fetchProducts,
    fetchVariants,
    fetchParameters,
    createOrder,
    createAuthRequest,
    submitAuth,
    getLastUpdate,
    fetchDeliveryIntervals,
    fetchPromoCode,
    fetchDeliveryPrice,
    fetchBonusSystem,
    fetchShopContacts,
    fetchPickupPoints,
    calculateVariants,
    fetchCurrentBuyerBonuses,
    fetchPricesByPickupPoints,
    fetchPickupIntervals,
    telegramAuth,
} from '../api/shop'
import { UPDATE_HTTP_IN_PROGRESS } from './types/mutations-types'
import createOptionsForDaData, { DA_DATA_URL } from '../utils/createOptionsForDaData'
import {
    DELIVERY_TYPES,
    NO_DELIVERY,
    PAYMENT_TYPES,
    TOAST,
} from '../constants/index'

Vue.use(Toasted)

export default {
    [actions.LOAD_OPTIONS_FILTERS] ({ commit }) {
        fetchOptionsFilters().then(
            options => commit(mutations.SET_OPTIONS_VARIANTS, options),
        )
    },

    [actions.LOAD_PRICE_RANGE] ({ commit }) {
        fetchPriceRange().then(
            range => commit(mutations.SET_PRICE_RANGE, range),
        )
    },

    [actions.LOAD_QUESTIONS] ({ commit }) {
        commit(mutations.CLEAR_CUSTOMER_ANSWERS)
        fetchQuestions().then(
            questions => commit(mutations.SET_QUESTIONS, questions),
        )
    },

    [actions.LOAD_CITIES] ({ state, commit }) {
        fetchCities()
            .then(cities => commit(mutations.SET_CITIES, cities.map(city => city.name)))
            .then(() => getDefaultCity())
            .then(defaultCity => {
                if (!state.customer.city) {
                    commit(mutations.UPDATE_CUSTOMER_CITY, {
                        city: defaultCity || state.cities[0],
                        exact: !!defaultCity,
                    })
                }
            })
    },

    [actions.LOAD_ALL_PRODUCTS] ({ commit }) {
        fetchProducts().then(products => commit(mutations.FILL_INIT_PRODUCTS, products))
    },

    [actions.LOAD_BONUS_SYSTEM] ({ commit }) {
        fetchBonusSystem()
            .then(bonusSystem => commit(mutations.SET_BONUS_SYSTEM, bonusSystem))
    },

    [actions.LOAD_SHOP_CONTACTS] ({ commit }) {
        fetchShopContacts()
            .then(contacts => commit(mutations.SET_CONTACTS, contacts))
    },

    [actions.LOAD_DELIVERY_PRICES] ({ state, getters, commit }) {
        if (state.orderDetails.delivery.type !== DELIVERY_TYPES.COURIER) {
            return // Не нужно отправлять даже пустые запросы на подгрузку адресов, если не задаем адрес доставки
        }
        commit(mutations.UPDATE_HTTP_IN_PROGRESS, true)
        fetchDeliveryPrice(state.deliveryAddress.address)
            .then(
                deliveryPrices => {
                    commit(mutations.SET_DELIVERY_PRICES, deliveryPrices)
                    if (
                        state.deliveryType === DELIVERY_TYPES.COURIER
                        && state.deliveryAddress.address.house.length > 0
                    ) {
                        // Обновляем пороговую стоимость доставки
                        commit(
                            mutations.UPDATE_DELIVERY_DETAILS_THRESHOLD_DELIVERY_PRICE,
                            getters.calculateDeliveryPrice,
                        )
                        // Обнуляем оставшуюся сумму, т.к. уже есть стоимость доставки
                        commit(mutations.UPDATE_DELIVERY_DETAILS_REMAINING_AMOUNT_DELIVERY_PRICE, 0)
                        commit(
                            mutations.UPDATE_DELIVERY_DETAILS_MESSAGE,
                            `Доставка: ${getters.calculateDeliveryPrice} ₽`,
                        )
                        // Проверка условия для бесплатной доставки
                        if (state.deliveryDetails.thresholdForDeliveryPrice === 0) {
                            // Обнуляем оставшуюся сумму, т.к. уже есть стоимость доставки
                            commit(mutations.UPDATE_DELIVERY_DETAILS_REMAINING_AMOUNT_DELIVERY_PRICE, 0)
                            commit(
                                mutations.UPDATE_DELIVERY_DETAILS_MESSAGE,
                                'Доставка: Бесплатно',
                            )
                        }
                        // Если сумма заказа меньше стоимости доставки, то thresholdForDeliveryPrice = null
                        // => обновляем остаток до мин. стоимости заказа
                        if (state.deliveryDetails.thresholdForDeliveryPrice === null) {
                            commit(
                                mutations.UPDATE_DELIVERY_DETAILS_REMAINING_AMOUNT_DELIVERY_PRICE,
                                getters.calculateRemainingAmount,
                            )
                            commit(
                                mutations.UPDATE_DELIVERY_DETAILS_MESSAGE,
                                `Еще ${getters.calculateRemainingAmount} ₽ до минимального заказа`,
                            )
                        }
                    }
                    commit(mutations.UPDATE_HTTP_IN_PROGRESS, false)
                },
            ).catch(() => {
                // с бэка приходит 404, если адрес не входит в зону, без сообщений
                commit(mutations.UPDATE_DELIVERY_DETAILS_MESSAGE, NO_DELIVERY)
                commit(mutations.UPDATE_HTTP_IN_PROGRESS, false)
            })
    },

    [actions.LOAD_STREET_DA_DATA] ({ state, commit }) {
        fetch(DA_DATA_URL, createOptionsForDaData(state, Vue.prototype.tokenDaData))
            .then(response => response.json())
            .then(result => {
                commit(mutations.SET_LOADER_ADDRESSES, result.suggestions)
            })
    },

    [actions.CHOOSE_DELIVERY_TYPE] ({ state, commit }, type) {
        commit(mutations.UPDATE_DELIVERY_DETAILS_MESSAGE, null)
        if (type === DELIVERY_TYPES.COURIER) {
            commit(mutations.CLEAR_ORDER_DETAILS_PICKUP)
            commit(mutations.UPDATE_PICKUP_POINTS_SELECTED, null)
        }
        if (type === DELIVERY_TYPES.PICKUP) {
            commit(mutations.CLEAR_ORDER_DETAILS_ADDRESS)
            commit(mutations.CLEAR_DELIVERY_DETAILS)
            commit(mutations.CLEAR_ADDRESSES)
            commit(mutations.CLEAR_LOAD_ADDRESSES)
            commit(mutations.UPDATE_ORDER_DETAILS_DELIVERY_PRICE, 0)
        }
        commit(mutations.UPDATE_ORDER_DETAILS_DELIVERY_DATE, null)
        commit(mutations.UPDATE_DELIVERY_TYPE, type)
        commit(mutations.UPDATE_ORDER_DETAILS_DELIVERY_TYPE, state.deliveryType)
        commit(mutations.UPDATE_ORDER_DETAILS_CHOOSE_ADDRESS, false)
    },

    [actions.CHOOSE_ADDRESS_COURIER] ({ state, commit }) {
        commit(mutations.CLEAR_ORDER_DETAILS_PICKUP)
        commit(mutations.UPDATE_ORDER_DETAILS_DELIVERY_TYPE, state.deliveryType)
        commit(mutations.UPDATE_ORDER_DETAILS_ADDRESS_HOUSE, state.deliveryAddress.address.house)
        commit(mutations.UPDATE_ORDER_DETAILS_ADDRESS_STREET, state.deliveryAddress.address.street)
        commit(mutations.UPDATE_ORDER_DETAILS_ADDRESS_CITY, state.deliveryAddress.address.city)
        commit(
            mutations.UPDATE_ORDER_DETAILS_ADDRESS_FULL_STREET,
            `${state.deliveryAddress.address.street}, ${state.deliveryAddress.address.house}`,
        )
        commit(mutations.UPDATE_ORDER_DETAILS_CHOOSE_ADDRESS, true)
        commit(mutations.UPDATE_ORDER_DETAILS_DELIVERY_PRICE, state.deliveryDetails.thresholdForDeliveryPrice)
    },

    [actions.CHOOSE_ADDRESS_PICKUP] ({ state, commit }) {
        commit(mutations.CLEAR_ORDER_DETAILS_ADDRESS)
        commit(mutations.UPDATE_ORDER_DETAILS_DELIVERY_TYPE, state.deliveryType)
        commit(mutations.UPDATE_ORDER_DETAILS_PICKUP_POINT, state.selectPickupPoint)
        commit(mutations.UPDATE_ORDER_DETAILS_CHOOSE_ADDRESS, true)
    },

    [actions.CHOOSE_PICKUP_POINT_ON_MAP] ({ state, commit }, id) {
        commit(
            mutations.UPDATE_ORDER_DETAILS_PICKUP_POINT,
            state.pickupPoints.find(pickupPoint => pickupPoint.apiId === id),
        )
    },

    [actions.LOAD_DELIVERY_INTERVALS] ({ commit }) {
        fetchDeliveryIntervals()
            .then(deliveryIntervals => {
                commit(mutations.SET_DELIVERY_INTERVALS, deliveryIntervals)
                commit(mutations.UPDATE_ORDER_DETAILS_DELIVERY_DATE, deliveryIntervals[0][0].date)
            })
    },

    [actions.LOAD_PICKUP_POINTS] ({ commit }) {
        fetchPickupPoints()
            .then(pickupPoints => commit(mutations.SET_PICKUP_POINTS, pickupPoints))
    },

    [actions.REQUEST_AUTH_CODE] ({ state, commit }) {
        commit(UPDATE_HTTP_IN_PROGRESS, true)
        createAuthRequest(state.authenticatorData.phone)
            .then(result => {
                commit(mutations.SET_AUTH_PHONE_REQUEST, result)
                commit(UPDATE_HTTP_IN_PROGRESS, false)
            })
            .catch(() => {
                commit(mutations.SET_AUTH_PHONE_REQUEST, null)
                commit(UPDATE_HTTP_IN_PROGRESS, false)
            })
    },

    [actions.CHECK_CODE] ({ state, commit }) {
        commit(UPDATE_HTTP_IN_PROGRESS, true)
        submitAuth(state.authenticatorData.phone, state.authenticatorData.code)
            .then(auth => {
                // Для того, чтобы тестировать, когда нет своих заказов.
                // auth.orders = {
                //     5: {
                //         number: 5015669235234187,
                //         createdAt: 1674286556,
                //         status: 'Выполнен',
                //         price: 2999,
                //         productsIds: [{
                //             id: 1,
                //             productName: 'гавайская',
                //             img: '',
                //             count: 2,
                //             price: 200,
                //         }],
                //         address: 'Томск, Герцена 18',
                //         bonusPaid: 200,
                //     }, 7: {
                //         number: 5015669235234188,
                //         createdAt: 1666940400,
                //         status: 'Выполнен',
                //         price: 2300,
                //         productsIds: [{
                //             id: 1,
                //             productName: 'гавайская',
                //             img: '',
                //             count: 2,
                //             price: 200,
                //         }, {
                //             id: 2,
                //             productName: 'Негавайская',
                //             img: '',
                //             count: 2,
                //             price: 200,
                //         }],
                //         address: 'Томск, Герцена 23',
                //         bonusPaid: 200
                //     }, 17: {
                //         number: 5015669235234188,
                //         createdAt: 1659164400,
                //         status: 'Выполнен',
                //         price: 2300,
                //         productsIds: [{
                //             id: 1,
                //             productName: 'Свинка',
                //             img: '',
                //             count: 2,
                //             price: 200,
                //         }, {
                //             id: 2,
                //             productName: 'Негавайская',
                //             img: '',
                //             count: 2,
                //             price: 200,
                //         },],
                //         address: 'Томск, Герцена 23',
                //         bonusPaid: 200
                //     },
                // }
                commit(mutations.SET_AUTH_DATA, auth)
                // window.location.href = Routing.generate('homepage')
                commit(UPDATE_HTTP_IN_PROGRESS, false)
            })
            .catch(() => {
                commit(mutations.SET_AUTH_DATA, null)
                commit(UPDATE_HTTP_IN_PROGRESS, false)
            })
    },

    [actions.LOGOUT_USER] ({ commit }) {
        commit(UPDATE_HTTP_IN_PROGRESS, true)
        commit(mutations.CLEAR_AUTH)
        commit(UPDATE_HTTP_IN_PROGRESS, false)
    },

    [actions.LOAD_PROMOCODE] ({ state, commit }) {
        fetchPromoCode(state.orderDetails.promoCode)
            .then(promoCode => {
                commit(mutations.SET_PROMOCODE, promoCode)
                showToast(state.promoCode.message)
            })
            .catch(error => {
                commit(mutations.SET_ERROR_PROMOCODE, error.message)
                showToast(error.message, 'error')
            })
    },

    [actions.LOAD_VARIANTS] ({ commit }, productId) {
        commit(mutations.SET_CURRENT_VARIANTS, [])
        commit(mutations.SET_PARAMETERS_LENGTH, 0)
        commit(mutations.SET_PARAMETERS_VARIANTS, [])

        fetchVariants(productId)
            .then(variants => {
                commit(mutations.SET_CURRENT_VARIANTS, variants)
                return fetchParameters(productId)
            })
            .then(parameters => {
                commit(mutations.SET_PARAMETERS_VARIANTS, parameters)
                commit(mutations.SET_PARAMETERS_LENGTH, parameters.length)
            })
    },

    /* BASKET LOGIC start */
    [actions.ADD_TO_BASKET] ({ state, commit, getters }, payload) {
        // payload: { currentProductId, currentModifierIds(массив, лежит в data) }
        calculateVariants(state.draftProduct, state.selectPickupPoint?.option?.apiId).then(variants => {
            const formattedVariants = variants.map(variant => {
                const currentProduct = state.initProducts.find(product => product.id === payload.currentProductId)

                return formatVariant(currentProduct, variant, payload.currentModifierIds)
            })

            formattedVariants.forEach(formattedVariant => {
                if (getters.findVariantInBasket(formattedVariant)) {
                    commit(
                        mutations.INCREMENT_VARIANT_QUANTITY,
                        getters.findVariantInBasket(formattedVariant).productId,
                    )
                } else {
                    try {
                        commit(mutations.ADD_PRODUCT_TO_BASKET, formattedVariant)
                        showToast('Товар добавлен в корзину')
                    } catch (e) {
                        showToast('Ошибка добавления в корзину', TOAST.TYPE_ERROR)
                    }
                }
            })
        }).catch(() => {
            showToast('Ошибка добавления в корзину', TOAST.TYPE_ERROR)
        })
    },
    /* BASKET LOGIC end */

    [actions.CREATE_ORDER] ({ state, commit }) {
        const { orderDetails, customer, basket } = state

        commit(mutations.UPDATE_HTTP_IN_PROGRESS, true)
        createOrder({ customer: { ...customer, ...orderDetails }, basket })
            .then(() => {
                commit(mutations.CLEAR_PROMOCODE)
                commit(mutations.CLEAR_BASKET)
                commit(mutations.CLEAR_ORDER_DETAILS)

                // TODO: Пока нет онлайн оплаты, делаю заглушку
                if (state.orderDetails.paymentType !== PAYMENT_TYPES.ONLINE) {
                    window.location.href = Routing.generate('order_success')
                }
                commit(mutations.UPDATE_HTTP_IN_PROGRESS, false)
            })
            .catch(error => {
                commit(mutations.UPDATE_HTTP_IN_PROGRESS, false)
                // Есть предположение, что может прийти массив ошибок
                if (Array.isArray(error)) {
                    // Если да, перебираем каждый элемент ошибки
                    error.forEach(errorItem => {
                        Vue.toasted.show(
                            `${errorItem.message}`,
                            {
                                duration: 2000,
                                containerClass: 'toasted-outer',
                                className: 'toasted-inner',
                                type: 'error',
                            },
                        )
                    })
                } else {
                    // Если ошибка не понятно какая, просто выводим "Ошибка"
                    Vue.toasted.show(
                        'Ошибка',
                        {
                            duration: 2000,
                            containerClass: 'toasted-outer',
                            className: 'toasted-inner',
                            type: 'error',
                        },
                    )
                }
            })
    },

    // Экшен не используется в default-new
    // [actions.SEND_FEEDBACK] ({ state, commit }) {
    //     const { customer, feedback } = state
    //     commit(mutations.UPDATE_HTTP_IN_PROGRESS, true)
    //     createOrder({
    //         ...customer,
    //         feedback: {
    //             name: feedback.name,
    //             email: feedback.email,
    //             phone: feedback.phone,
    //             comment: feedback.message,
    //         },
    //     }).then(
    //         () => {
    //             commit(mutations.CLEAR_FEEDBACK_FORM)
    //             Vue.toasted.show('Обращение принято. Мы свяжемся с вами.', { duration: 5000 })
    //             commit(mutations.UPDATE_HTTP_IN_PROGRESS, false)
    //         },
    //         () => commit(mutations.UPDATE_HTTP_IN_PROGRESS, false),
    //     )
    // },

    [actions.CLEAR_PRODUCTS_ON_CATALOG_UPDATE] ({ commit }) {
        const STATE_CLEANER_KEY = 'qeep-state-cleaner'
        getLastUpdate().then(lastUpdated => {
            const needReload = localStorage.getItem(STATE_CLEANER_KEY) !== lastUpdated
            if (needReload) {
                commit(mutations.CLEAR_PRODUCTS_AND_DRAFT_PRODUCTS)
                localStorage.setItem(STATE_CLEANER_KEY, lastUpdated)
            }
        })
    },

    // eslint-disable-next-line object-curly-newline
    [actions.CHANGE_DRAFT_QUANTITY] ({ commit, id, delta, price }) {
        commit(
            mutations.CHANGE_DRAFT_PRODUCT_QUANTITY,
            {
                id,
                delta,
                price: parseInt(price, 10),
            },
        )
    },

    // eslint-disable-next-line object-curly-newline
    [actions.CHANGE_DISCRIMINATED_QUANTITY] ({ commit, id, delta, price, discriminator }) {
        commit(
            mutations.CHANGE_DRAFT_DISCRIMINATED_QUANTITY,
            {
                id,
                delta,
                price: parseInt(price, 10),
                discriminator,
            },
        )
    },

    [actions.LOAD_BUYER_BONUSES] ({ commit }) {
        commit(UPDATE_HTTP_IN_PROGRESS, true)
        fetchCurrentBuyerBonuses()
            .then(bonuses => {
                commit(mutations.UPDATE_BUYER_BONUSES, bonuses)
                commit(UPDATE_HTTP_IN_PROGRESS, false)
            })
            .catch(() => {
                commit(mutations.UPDATE_BUYER_BONUSES, null)
                commit(UPDATE_HTTP_IN_PROGRESS, false)
            })
    },

    [actions.SET_PRICES_BY_PICKUP_POINTS] ({ commit }) {
        fetchPricesByPickupPoints()
            .then(res => {
                commit(mutations.SET_PRICES_BY_PICKUP_POINTS, res)
            })
            .catch(() => {
                commit(mutations.SET_PRICES_BY_PICKUP_POINTS, {})
            })
    },

    [actions.UPDATE_TELEGRAM_USER] ({ commit }, userData) {
        commit(UPDATE_HTTP_IN_PROGRESS, true)
        return telegramAuth(userData)
            .then(result => {
                commit(mutations.SET_TELEGRAM_USER, result)
                commit(UPDATE_HTTP_IN_PROGRESS, false)
                return result
            })
            .catch(error => {
                commit(UPDATE_HTTP_IN_PROGRESS, false)
                throw error
            })
    },

    [actions.SET_PRICES_BY_PICKUP_POINTS] ({ commit }) {
        fetchPricesByPickupPoints()
            .then(res => {
                commit(mutations.SET_PRICES_BY_PICKUP_POINTS, res)
            })
            .catch(() => {
                commit(mutations.SET_PRICES_BY_PICKUP_POINTS, {})
            })
    },

    [actions.SET_PICKUP_INTERVALS] ({ commit }, pickupPointApiId) {
        commit(mutations.UPDATE_HTTP_IN_PROGRESS, true)
        fetchPickupIntervals(pickupPointApiId)
            .then(res => {
                commit(mutations.SET_PICKUP_INTERVALS, res)
                commit(mutations.UPDATE_HTTP_IN_PROGRESS, false)
            })
            .catch(() => {
                commit(mutations.SET_PICKUP_INTERVALS, [])
                commit(mutations.UPDATE_HTTP_IN_PROGRESS, false)
            })
    },
}
