/* TODO: сделать обработку на бэке */
export const phoneFormatter = (phone, currentLength, separationElement, separationInterval) => {
    const phoneMask = new RegExp(`.{1,${separationInterval}}`, 'g')
    return phone
        .replace(/[^0-9]/g, '')
        .slice(-currentLength)
        .match(phoneMask)
        .join(separationElement)
}

export function phoneNumberMask(rawValue) {
    // Удаляем все символы, кроме цифр
    let cleaned = rawValue.replace(/\D/g, '')

    // Если номер начинается с 7 или 8, меняем его на +7
    if (cleaned.startsWith('7') || cleaned.startsWith('8')) {
        cleaned = '7' + cleaned.slice(1)
    }

    // Применяем маску для номеров России
    if (cleaned.length >= 10) {
        return cleaned
            .replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5')
            .replace(/[-() ]+\s*$/gu, '')
    } else {
        return cleaned // Возвращаем, если не хватает цифр
    }
}
