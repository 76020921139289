<template>
  <div class="app-select">
    <span class="app-select__label">{{ label }}</span>
    <button
      class="app-select__button"
      :class="{ 'app-select__button_active': isOpen, 'error': isError }"
      :disabled="!options.length || isDisabled"
      @click="isOpen = !isOpen"
    >
      <span class="app-select__button-text">{{ selectedName }}</span>
      <span class="app-select__button-icon">
        <!--TODO: проблема прокинуть путь для картинки-->
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.9543 6.61598C17.7199 6.38164 17.402 6.25 17.0706 6.25C16.7391 6.25
              16.4212 6.38164 16.1868 6.61598L9.99933 12.8035L3.81183 6.61598C3.57608
              6.38829 3.26032 6.26229 2.93258 6.26514C2.60483 6.26799 2.29132 6.39945
              2.05956 6.63121C1.8278 6.86297 1.69633 7.17648 1.69349 7.50423C1.69064
              7.83198 1.81663 8.14773 2.04433 8.38348L9.11558 15.4547C9.34999 15.6891
              9.66787 15.8207 9.99933 15.8207C10.3308 15.8207 10.6487 15.6891 10.8831
              15.4547L17.9543 8.38348C18.1887 8.14907 18.3203 7.83119 18.3203 7.49973C18.3203
              7.16828 18.1887 6.85039 17.9543 6.61598Z"
            fill="#333333"
          />
        </svg>
      </span>
    </button>
    <ul class="app-select__list" :class="{ 'app-select__list_visible': isOpen }">
      <li
        v-for="(option, i) of options"
        :key="i"
        class="app-select__list-item"
        @click="onClick(option)"
      >
        {{ option.name }}
        <span class="app-select__list-item-label" v-if="option.label">~ {{ option.label }}</span>
      </li>
    </ul>
    <span
      v-if="isError"
      class="app-select__text-error"
    >
      Обязательное поле
    </span>
    <span
      v-if="customErrorMessage && !isError && !httpInProgress"
      class="app-select__text-error"
      v-cloak
    >
      {{ customErrorMessage }}
    </span>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'appSelect',
  emits: [
    'change',
    'validation-change',
  ],
  props: {
    options: {
      type: Array,
      required: true,
    },
    defaultSelectedOption: {
      type: Object,
      default: () => {},
    },
    label: {
      type: String,
      default: '',
    },
    preSelectName: {
      type: String,
      default: 'Выберите',
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    showValidationError: {
      type: Boolean,
      default: false,
    },
    customErrorMessage: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      selected: {
        name: ''
      },
      isOpen: false,
      isInvalid: false,
    };
  },
  methods: {
    onClick(option) {
      this.selected = option
      this.$emit('change', this.selected)
      this.$emit('validation-change', !this.isError)
      this.isOpen = false
    },
    closeByClickOutside(event) {
      !this.$el.contains(event.target) && (this.isOpen = false)
    },
    setDefaultOption() {
      if (JSON.stringify(this.defaultSelectedOption) !== '{}' || this.defaultSelectedOption) {
        this.selected = this.defaultSelectedOption
      } else {
        this.selected = {}
      }
    },
  },
  computed: {
    ...mapState({
      httpInProgress: state => state.httpInProgress,
    }),
    selectedName() {
      return this.selected?.name ?? this.preSelectName
    },
    isError() {
      return this.isRequired && !this.selected.name && this.isInvalid
    },
  },
  mounted() {
    this.setDefaultOption()
    document.addEventListener('click', this.closeByClickOutside)
  },
  updated() {
    if (this.selected?.name) {
      this.$emit('validation-change', true)
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeByClickOutside)
  },
  watch: {
    options() {
      // перезапись селекта - при изменении входного массива
      this.setDefaultOption()
    },
    // перезапись выбранного селекта - при изменении в другом месте
    defaultSelectedOption() {
      this.setDefaultOption()
    },
    showValidationError(newValue) {
      this.isInvalid = !!newValue
    }
  },
}
</script>
