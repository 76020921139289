import yml from '@config/parameters.yml'

export const DA_DATA_URL = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address'

const createOptionsForDaData = (state, token) => {
    const preset = JSON.parse(yml.parameters['app.da_data_preset'])

    return {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
            Accept: 'application/json',
            Authorization: `Token ${token}`,
        },
        body: JSON.stringify({
            query: state.deliveryAddress.address.fullStreet,
            ...preset,
        }),
    }
}

export default (state, token) => createOptionsForDaData(state, token)
