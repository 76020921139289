<template>
  <div class="bonus-program__bonus-container">
    <div class="bonus-program__bonus-header">
      <p class="bonus-program__bonus-title">Бонусная программа</p>
      <button
          v-if="!showFullDescription"
          class="bonus-program__bonus-button-question"
          @click="toggleBonusInfo"
      >
        <i class="mdi mdi-help mdi-14px" style="color: var(--main-font-secondary-color)"></i>
      </button>
    </div>
    <div class="bonus-program__bonus-wrapper">
      <p class="bonus-program__bonus-info" v-if="isAuthenticated">
        <span class="bonus-program__bonus-info-text">Ваши баллы</span>
        <span class="bonus-program__bonus-info-count" v-cloak>
          {{ customer.bonuses || 0 }}
        </span>
      </p>
      <div class="bonus-program__bonus-desc" v-if="bonusSystem" v-cloak>
        <div class="bonus-program__bonus-desc-text" v-html="shortDescriptionBonus"></div>
      </div>
    </div>
    <div class="bonus-program__accordion">
      <div
          :class="{
          'bonus-program__accordion-text bonus-program__bonus-extra-info--open': showFullDescription || showBonusInfo,
          'bonus-program__bonus-extra-info': true
        }"
      >
        <div v-html="fullDescriptionBonus"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'bonusProgram',
  props: {
    fullDescriptionBonus: {
      type: String,
      required: true,
    },
    shortDescriptionBonus: {
      type: String,
      required: true,
    },
    showFullDescription: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showBonusInfo: false,
  }),
  methods: {
    toggleBonusInfo() {
      this.showBonusInfo = !this.showBonusInfo
    },
  },
  computed: {
    ...mapState({
      isAuthenticated: state => state.customer.isAuthenticated,
      bonusSystem: state => state.bonusSystem,
      customer: state => state.customer,
    }),
  }
}
</script>
