/* eslint space-before-function-paren: ["error", "always"] */
/* eslint no-param-reassign: ["error", { "props": false }] */
import * as types from './types/mutations-types'

import {
    filterVariantsByParameters,
    getVariantFromBasketById,
    findVariantWithoutModifiers,
    mapVariantsToParameters,
} from '@utils/variants'
import { formatAddress } from '../utils/formatAddress'
import { promoCodeCalculator } from './getters'

import { PAYMENT_TYPES } from '@/constants'

export default {
    // Типы действий для управления количеством продуктов
    [types.INCREMENT_VARIANT_QUANTITY] (state, productId) {
        const variantById = getVariantFromBasketById(state.basket, productId)
        const variantWithoutModifiers = findVariantWithoutModifiers(state.basket, productId)
        const variant = variantById || variantWithoutModifiers

        if (variant) {
            variant.quantity++
        }
    },

    [types.DECREMENT_VARIANT_QUANTITY] (state, productId) {
        const variantById = getVariantFromBasketById(state.basket, productId)
        const variantWithoutModifiers = findVariantWithoutModifiers(state.basket, productId)
        const variant = variantWithoutModifiers || variantById

        if (variant && variant.quantity > 1) {
            variant.quantity--
        } else if (variant) {
            this.commit(types.REMOVE_FROM_BASKET, variant.productId)
        }
    },

    [types.REMOVE_FROM_BASKET] (state, productId) {
        state.basket = state.basket.filter(v => v.productId !== productId)
    },

    // Типы действий для модальных окон
    [types.OPEN_MODAL] (state) {
        state.isModalOpen = true
    },

    [types.CLOSE_MODAL] (state) {
        state.isModalOpen = false
    },


    // Типы действий для корзины
    [types.ADD_PRODUCT_TO_BASKET] (state, product) {
        state.basket.push(product)
    },

    [types.REMOVE_FROM_BASKET] (state, productId) {
        state.basket = state.basket.filter(v => String(v.productId) !== String(productId))
    },


    // Типы действий для очистки данных
    [types.CLEAR_DRAFT_PRODUCT] (state) {
        state.draftProduct = []
    },

    [types.CLEAR_PRODUCTS] (state) {
        state.products = []
    },


    [types.CLEAR_PRODUCT_VARIANTS_LIST] (state) {
        state.productVariantsList = []
    },

    [types.RESET_SELECTED_MODIFIERS] (state) {
        state.selectedModifiers = []
        state.productPriceModifiers = 0
    },


    [types.CLEAR_PRODUCT] (state) {
        state.product = {
            id: '',
            title: '',
            body: '',
            description: '',
            price: '',
            images: [],
            isBuyable: true,
            variants: [{
                parameters: {
                    key: '',
                    value: '',
                },
            }],
        }
    },

    [types.CLEAR_AUTH_REQUEST] (state) {
        state.authenticatorData.error = null
    },

    [types.CLEAR_AUTH_PHONE] (state) {
        state.authenticatorData.phone = ''
        state.authenticatorData.error = null
    },

    [types.CLEAR_AUTH] (state) {
        state.authenticatorData.error = null
        state.customer = {
            ...state.customer,
            buyerId: null,
            bonuses: null,
            rawName: null,
            isAuthenticated: false,
            name: '',
            phone: '',
        }
        state.authenticatorData.phone = null
        state.authenticatorData.code = null
    },

    [types.CLEAR_PROMOCODE] (state) {
        state.promoCode.name = ''
        state.promoCode.discount = null
        state.promoCode.discountType = null
        state.promoCode.discountPrice = null

        state.orderDetails.discountPrice = null
        state.promoCode.message = ''
    },

    [types.CLEAR_LOAD_ADDRESSES] (state) {
        state.deliveryAddress.loadedAddresses = null
    },

    [types.CLEAR_ADDRESSES] (state) {
        state.deliveryAddress.address.city = ''
        state.deliveryAddress.address.fullStreet = ''
        state.deliveryAddress.address.house = ''
        state.deliveryAddress.address.street = ''
    },

    [types.CLEAR_DELIVERY_DETAILS] (state) {
        state.deliveryDetails.deliveryPrices = null
        state.deliveryDetails.thresholdForDeliveryPrice = null
        state.deliveryDetails.remainingAmount = null
        state.deliveryDetails.message = ''
    },

    [types.CLEAR_ORDER_DETAILS_ADDRESS] (state) {
        state.orderDetails.delivery.address.street = ''
        state.orderDetails.delivery.address.house = ''
        state.orderDetails.delivery.address.fullStreet = ''
        state.orderDetails.isAddressChosen = false
    },

    [types.CLEAR_ORDER_DETAILS_PICKUP] (state) {
        state.orderDetails.delivery.pickup = {
            address: '',
            name: '',
        }
    },

    [types.CLEAR_PRODUCTS_AND_DRAFT_PRODUCTS] (state) {
        state.products = []
        state.draftProducts = []
    },

    [types.CLEAR_CUSTOMER_ANSWERS] (state) {
        state.customer.answers = []
    },

    [types.CLEAR_BASKET] (state) {
        state.basket = []
    },

    [types.CLEAR_FEEDBACK_FORM] (state) {
        state.feedback.name = ''
        state.feedback.email = ''
        state.feedback.phone = ''
        state.feedback.message = ''
    },

    [types.CLEAR_ORDER_DETAILS](state) {
        state.orderDetails.paidByBonuses = null
        state.orderDetails.promoCode = null
        state.orderDetails.discountPrice = null

        state.orderDetails.delivery.date = ''
        state.orderDetails.delivery.interval = null
        state.orderDetails.delivery.deliveryPrice = 0

        state.orderDetails.onlinePayment = false
        state.orderDetails.paymentType = PAYMENT_TYPES.COURIER_CASH
        state.orderDetails.addressComment = ''
        state.orderDetails.orderComment = ''
    },


    // Типы действий для обновления изображений
    [types.ACTIVATE_NEXT_IMAGE] (state) {
        if (state.currentImageIndex < state.product.images.length - 1) {
            state.currentImageIndex++
        }
    },

    [types.ACTIVATE_PREV_IMAGE] (state) {
        if (state.currentImageIndex > 0) {
            state.currentImageIndex--
        }
    },

    [types.RESET_ACTIVE_IMAGE] (state) {
        state.activeImageIndex = 0
    },

    [types.RESET_IMAGE_POSITION] (state) {
        state.currentImageIndex = 0
    },

    [types.SET_ACTIVE_IMAGE] (state, index) {
        state.mainImg = state.product.images[index]
        state.activeImageIndex = index
    },

    [types.SET_CROPPED_IMAGE] (state, image) {
        state.cropped.image = image
    },

    [types.SET_CROPPED_IMAGE_DATA] (state, data) {
        state.cropped.imageData = data
    },

    [types.SET_CROPPED_IMAGE_ORIGIN] (state, image) {
        state.cropped.imageOrigin = image
    },

    [types.SET_IMAGE_PATH] (state, path) {
        state.pathImg = path
    },

    [types.SET_MAIN_IMAGE] (state, img) {
        state.mainImg = img
    },

    [types.UPDATE_CROPPED_IMAGE] (state, value) {
        state.cropped.image = value
    },

    [types.UPDATE_CROPPED_IMAGE_ORIGIN] (state, value) {
        state.cropped.imageOrigin = value
    },

    [types.UPDATE_CROPPED_IMAGE_DATA] (state, value) {
        state.cropped.imageData = value },


    // Типы действий для слайдов
    [types.SET_SLIDE_LINK_OPTIONS] (state, value) {
        state.slideLinkOptions = {
            ...state.slideLinkOptions, ...value,
        }
    },

    [types.SET_SLIDES] (state, value) {
        state.slides = value
    },

    [types.SET_SLIDE_PREVIEW] (state, slidePreviewData) {
        state.slidePreview = slidePreviewData
    },


    // Типы действий для формы обратной связи
    [types.UPDATE_FEEDBACK_NAME] (state, name) {
        state.feedback.name = name
    },

    [types.UPDATE_FEEDBACK_EMAIL] (state, email) {
        state.feedback.email = email
    },

    [types.UPDATE_FEEDBACK_PHONE] (state, phone) {
        state.feedback.phone = phone
    },

    [types.UPDATE_FEEDBACK_MESSAGE] (state, message) {
        state.feedback.message = message
    },


    // Типы действий для вопросов
    [types.SET_QUESTIONS] (state, questions) {
        state.questions = questions
    },


    // Типы действий для аутентификации
    [types.UPDATE_AUTH_PHONE] (state, phone) {
        state.authenticatorData.phone = phone
    },

    [types.UPDATE_AUTH_CODE] (state, code) {
        state.authenticatorData.code = code
    },

    [types.SET_AUTH_PHONE_REQUEST] (state, response) {
        // response = 'Введите код, который пришел вам в смс'
        // TODO: По-хорошему наверное лучше возвращать статус
        state.authenticatorData.error = response ? null : 'Произошла ошибка. Попробуйте позже.'
    },

    [types.SET_CUSTOM_AUTH_PHONE_ERROR] (state, textError) { state.authenticatorData.error = textError },

    [types.SET_AUTH_DATA] (state, authData) {
        if (authData) {
            state.customer = {
                ...state.customer,
                ...authData,
                isAuthenticated: true
            }
            // История заказов еще не реализована
            // state.authenticatorData.client.orders = authData.orders
        } else {
            state.authenticatorData.error = 'Неправильный код'
        }
    },


    // Типы действий для специальных функций
    [types.SET_IS_USING_SHOP_LOGIC] (state, value) {
        state.isUsingShopLogic = value
    },

    [types.UPDATE_HTTP_IN_PROGRESS] (state, flag) {
        state.httpInProgress = flag
    },

    [types.SET_SCREEN_WIDTH] (state, width) {
        state.screenWidth = width
    },


    // Типы действий для доставки
    [types.UPDATE_DELIVERY_TYPE] (state, type) {
        state.deliveryType = type
    },

    [types.SET_DELIVERY_INTERVALS] (state, deliveryIntervals) {
        state.deliveryIntervals = deliveryIntervals
    },


    // Типы действий для доставки курьером
    [types.UPDATE_DELIVERY_ADDRESS_FULL_STREET] (state, street) {
        state.deliveryAddress.address.fullStreet = street
    },

    [types.SET_LOADER_ADDRESSES] (state, value) {
        state.deliveryAddress.loadedAddresses = value
    },

    [types.SET_LOADED_ADDRESS_IN_ADDRESS] (state, value) {
        const { street_with_type, house, city } = value

        state.deliveryAddress.address.street = street_with_type ?? ''
        state.deliveryAddress.address.house = house ?? ''
        state.deliveryAddress.address.city = city ?? ''

        state.deliveryAddress.address.fullStreet = formatAddress(value)
    },


    // Типы действий для доставки самовывоза
    [types.SET_PICKUP_POINTS] (state, pickupPoints) {
        state.pickupPoints = pickupPoints
    },

    [types.UPDATE_PICKUP_POINTS_SELECTED] (state, pickupPoint) {
        state.selectPickupPoint = pickupPoint
    },


    // Типа действия для цены доставки
    [types.SET_DELIVERY_PRICES] (state, deliveryPrices) {
        state.deliveryDetails.deliveryPrices = deliveryPrices
    },

    [types.UPDATE_DELIVERY_DETAILS_MESSAGE] (state, message) {
        state.deliveryDetails.message = message ?? ''
    },

    [types.UPDATE_DELIVERY_DETAILS_THRESHOLD_DELIVERY_PRICE] (state, thresholdForDeliveryPrice) {
        state.deliveryDetails.thresholdForDeliveryPrice = thresholdForDeliveryPrice
    },

    [types.UPDATE_DELIVERY_DETAILS_REMAINING_AMOUNT_DELIVERY_PRICE] (state, remainingAmount) {
        state.deliveryDetails.remainingAmount = remainingAmount
    },


    // Типы действий для обновления информации о клиенте
    [types.UPDATE_CUSTOMER_CITY] (state, { city, exact = false }) {
        state.customer.city = city
        state.cityExact = exact
    },

    [types.UPDATE_CUSTOMER_NAME] (state, name) {
        state.customer.firstName = name
    },

    [types.UPDATE_CUSTOMER_PHONE] (state, phone) {
        state.customer.phone = phone
    },

    [types.SET_PAID_BY_BONUSES_IN_CUSTOMER] (state) {
        state.customer.paidByBonuses = state.customer.paidByBonusesLocal
    },

    [types.RESET_PAID_BY_BONUSES] (state) {
        state.orderDetails.paidByBonuses = ''
    },

    [types.UPDATE_BUYER_BONUSES] (state, bonuses) {
        state.customer.bonuses = bonuses ? bonuses.bonuses : null
    },

    // Типы действий для обновления информации о заказе
    [types.UPDATE_ORDER_DETAILS_CHOOSE_ADDRESS] (state, flag) {
        state.orderDetails.isAddressChosen = flag
    },

    [types.UPDATE_ORDER_DETAILS_PICKUP_POINT] (state, pickupPoint) {
        state.orderDetails.delivery.pickup = pickupPoint ? pickupPoint.option : null
    },

    [types.UPDATE_ORDER_DETAILS_PAYMENT_TYPE] (state, paymentType) {
        state.orderDetails.paymentType = paymentType
    },

    [types.UPDATE_ORDER_DETAILS_DELIVERY_TYPE] (state, type) {
        state.orderDetails.delivery.type = type
    },

    [types.UPDATE_ORDER_DETAILS_ALLOW_PERSONAL_DATA_PROCESSING] (state, allow) {
        state.orderDetails.allowPersonalDataProcessing = allow
    },

    [types.UPDATE_ORDER_DETAILS_DELIVERY_DATE] (state, date) {
        state.orderDetails.delivery.date = date || (
            state.deliveryIntervals[0]
            && state.deliveryIntervals[0][0]
            && state.deliveryIntervals[0][0].date
        )

        const deliveryInterval = state.deliveryIntervals.find(deliveryDate => {
            return deliveryDate[0] && deliveryDate[0].date === state.orderDetails.delivery.date
        })

        if (deliveryInterval !== undefined) {
            state.orderDetails.delivery.interval = deliveryInterval[0]
        } else {
            state.orderDetails.delivery.interval = deliveryInterval
        }
    },

    [types.UPDATE_ORDER_DETAILS_DELIVERY_INTERVAL] (state, interval) {
        if (!interval) {
            return
        }

        state.orderDetails.delivery.interval = interval
    },

    [types.UPDATE_ORDER_DETAILS_DELIVERY_PRICE] (state, price) {
        state.orderDetails.delivery.price = price
    },

    [types.UPDATE_ORDER_DETAILS_ADDRESS_APARTMENT] (state, apartment) {
        state.orderDetails.delivery.address.apartment = apartment
    },

    [types.UPDATE_ORDER_DETAILS_ADDRESS_FULL_STREET] (state, fullStreet) {
        state.orderDetails.delivery.address.fullStreet = fullStreet
    },

    [types.UPDATE_ORDER_DETAILS_ADDRESS_CITY] (state, city) {
        state.orderDetails.delivery.address.city = city
    },

    [types.UPDATE_ORDER_DETAILS_ADDRESS_DOOR_CODE] (state, doorCode) {
        state.orderDetails.delivery.address.doorCode = doorCode
    },

    [types.UPDATE_ORDER_DETAILS_ADDRESS_ENTRANCE] (state, entrance) {
        state.orderDetails.delivery.address.entrance = entrance
    },

    [types.UPDATE_ORDER_DETAILS_ADDRESS_FLOOR] (state, floor) {
        state.orderDetails.delivery.address.floor = floor
    },

    [types.UPDATE_ORDER_DETAILS_ADDRESS_STREET] (state, street) {
        state.orderDetails.delivery.address.street = street
    },

    [types.UPDATE_ORDER_DETAILS_ADDRESS_HOUSE] (state, house) {
        state.orderDetails.delivery.address.house = house
    },


    // Типы действий для бонусной системы
    [types.UPDATE_PAID_BY_BONUSES_LOCAL] (state, { paidByBonuses, bonusesAvailableToUse }) {
        if (paidByBonuses < 0) {
            return state.customer.paidByBonusesLocal = 0
        }

        if (bonusesAvailableToUse > state.customer.bonuses) {
            switch (true) {
                case paidByBonuses > state.customer.bonuses:
                    return state.customer.paidByBonusesLocal = state.customer.bonuses
                case paidByBonuses > bonusesAvailableToUse:
                    return state.customer.paidByBonusesLocal = bonusesAvailableToUse
            }
        } else {
            switch (true) {
                case paidByBonuses > bonusesAvailableToUse:
                    return state.customer.paidByBonusesLocal = bonusesAvailableToUse
                case paidByBonuses > state.customer.bonuses:
                    return state.customer.paidByBonusesLocal= state.customer.bonuses
            }
        }

        state.customer.paidByBonusesLocal = paidByBonuses
    },

    [types.UPDATE_PROMOCODE] (state, value) {
        state.promoCode.name = value
    },

    [types.SET_BONUS_SYSTEM] (state, bonusSystem) {
        state.bonusSystem = bonusSystem
    },

    [types.SET_PROMOCODE] (state, promoCode) {
        state.promoCode.name = promoCode.name
        state.promoCode.discount = promoCode.discount
        state.promoCode.discountType = promoCode.discountType
        state.promoCode.discountPrice = promoCodeCalculator(state)

        state.orderDetails.discountPrice = promoCodeCalculator(state)
        state.promoCode.message = 'Промокод успешно применён'
    },

    [types.SET_ERROR_PROMOCODE] (state, message) {
        // чтобы после ошибке не оставились данные другого промокода
        state.promoCode.name = ''
        state.promoCode.discount = null
        state.promoCode.discountType = null
        state.promoCode.discountPrice = null

        state.orderDetails.discountPrice = null
        state.orderDetails.promoCode = ''
        state.promoCode.message = message
    },

    // Действия для управления вариантами продукта
    [types.SET_CURRENT_VARIANTS] (state, variants) {
        state.currentVariants = variants
    },

    [types.SET_PARAMETERS_LENGTH] (state, length) {
        state.lengthParameters = length
    },

    [types.SET_PARAMETERS_VARIANTS] (state, parameters) {
        state.availableParameters = parameters
    },

    [types.SET_OPTIONS_VARIANTS] (state, options) {
        state.filters.options = Object.values(options).filter(i => i)
    },

    [types.RESET_PRODUCT_PARAMETERS] (state) {
        state.draftProducts.forEach(product => {
            product.parameters = []
            product.discriminated.forEach(discriminated => {
                discriminated.parameters = []
            })
        })
    },


    // Действия для управления модификаторами
    [types.SELECT_MODIFIER] (state, modifier) {
        // toggle active class
        const currentAmountSelectedModifiers = state.selectedModifiers.filter(
            el => el.groupModifier === state.selectedGroupModifier.apiId,
        ).length

        const modifierClone = { ...modifier }

        modifierClone.groupModifier = state.selectedGroupModifier.apiId
        modifierClone.modifier = modifier.id
        modifierClone.amount = 1
        const findModifierInSelected = state.selectedModifiers.find(
            gm => gm.id === modifierClone.id,
        )

        if (findModifierInSelected) {
            state.selectedModifiers = state.selectedModifiers.filter(
                item => item !== findModifierInSelected,
            )
        } else if (currentAmountSelectedModifiers >= state.selectedGroupModifier.maxAmount) {
            state.selectedModifiers.find((modifierLoc, index) => {
                if (modifierLoc.groupModifier === state.selectedGroupModifier.apiId) {
                    state.selectedModifiers.splice(index, 1)
                    return true
                }
                return false
            })
            state.selectedModifiers.push(modifierClone)
            state.selectedModifiers.sort((a, b) => a.id - b.id)
        } else {
            state.selectedModifiers.push(modifierClone)
            state.selectedModifiers.sort((a, b) => a.id - b.id)
        }
        state.productPriceModifiers = state.selectedModifiers.reduce(
            (sum, modifierLoc) => sum + modifierLoc.price,
            0,
        )
    },

    [types.SELECT_GROUP_MODIFIER] (state, groupModifier) {
        // groupModifier всегда одна и та же ссылка на объект
        if (state.selectedGroupModifier !== groupModifier) {
            state.selectedGroupModifier = groupModifier
        } else {
            state.selectedGroupModifier = null
        }
    },

    [types.SET_MODIFIERS] (state, modifiers) {
        state.modifiers = modifiers
    },

    [types.RESET_ACTIVE_PRICE_VARIANT] (state) {
        state.productPriceModifiers = 0
        state.activePriceIndex = 0
    },


    // Действия для управления фильтрами
    [types.SET_PRICE_RANGE] (state, { min, max }) {
        state.filters.price.min = min
        state.filters.price.max = max
    },

    [types.UPDATE_FILTER_FROM_PRICE] (state, from) {
        state.filters.active.price.from = from
    },

    [types.UPDATE_FILTER_TO_PRICE] (state, to) {
        state.filters.active.price.to = to
    },

    [types.UPDATE_FILTER_OPTIONS] (state, options) {
        state.filters.active.options = options
    },

    [types.TOGGLE_FILTER_OPTION] (state, { key, value }) {
        const activeOption = state.filters.active.options
            .find(option => option.key === key.toLowerCase())
        if (activeOption && activeOption.values.find(v => v === value.toLowerCase())) {
            activeOption.values = activeOption.values.filter(v => v !== value.toLowerCase())
        } else if (activeOption) {
            activeOption.values.push(value.toLowerCase())
        } else {
            state.filters.active.options.push({
                key: key.toLowerCase(),
                values: [value.toLowerCase()],
            })
        }
    },


    // Действия для управления данными о продукте
    [types.FILL_INIT_PRODUCTS] (state, products) {
        state.initProducts = products
    },

    [types.SET_PRODUCT] (state, product) {
        state.product = product

        const variants = {}
        const selectedParametersIndexMap = {}

        product.variants.forEach(
            variant => {
                variant.parameters.forEach(
                    parameter => {
                        if (variants[parameter.key]) {
                            variants[parameter.key].add(parameter.value)
                        } else {
                            selectedParametersIndexMap[parameter.key] = 0
                            variants[parameter.key] = new Set([parameter.value])
                        }
                    },
                )
            },
        )

        state.selectedParametersIndexMap = selectedParametersIndexMap
        state.productVariantsList = variants
    },

    [types.SET_AMOUNT] (state, amount) {
        state.amountProduct = amount
    },


    // Действия для управления городами и контактами
    [types.SET_CITIES] (state, cities) {
        state.cities = cities
    },

    [types.CLEAR_DRAFT_PRODUCT] (state) {
        state.draftProduct = []
    },

    [types.SET_CONTACTS] (state, contacts) {
        state.shopContacts = contacts
    },


    // Действия для управления параметрами
    [types.CHANGE_TOGGLE_PARAMETER] (state, productId) {
        const parameters = []
        const parameter = {}

        Object.keys(state.productVariantsList).forEach(variant => {
            parameter.key = variant
            parameter.value = [
                ...state.productVariantsList[variant],
            ][state.selectedParametersIndexMap[variant]]
            parameters.push({ ...parameter })
        })

        state.draftProduct = [{
            productId,
            pickupPointId: state.selectPickupPoint ? state.selectPickupPoint.option.apiId : null,
            quantity: 1,
            parameters,
        }]
    },

    [types.SET_ACTIVE_TOGGLE] (state, keyIndexPairParametersMap) {
        // keyIndexPairParametersMap содержит имя ключа и цифру под которой
        // отрисовывается данный ключ
        const [f, s] = keyIndexPairParametersMap
        state.selectedParametersIndexMap[f] = s

        const parameter = {}

        Object.keys(state.productVariantsList).forEach(variantKey => {
            // variantKey это имя ключа, по-которому обращаются в VariantsList,
            // чтобы вытащить нужный параметр
            parameter[variantKey] = [
                ...state.productVariantsList[variantKey],
            ][state.selectedParametersIndexMap[variantKey]]
        })

        state.activePriceIndex = state.product.variants.findIndex(
            variant => variant.parameters.reduce((acc, param) => {
                const parameterCount = Object.keys(parameter).filter(
                    paramKey => paramKey === param.key && parameter[paramKey] === param.value,
                ).length

                return acc + parameterCount
            }, 0) === Object.keys(parameter).length,
        )
    },

    [types.RESET_ACTIVE_TOGGLE] (state) {
        state.selectedParametersIndexMap = {}
    },


    // Действия для управления draft
    [types.CHANGE_DRAFT_PRODUCT_QUANTITY] (state, { id, delta, price }) {
        const existedProduct = state.draftProducts.find(p => p.id === id)

        if (existedProduct) {
            existedProduct.price = price
            if ((delta < 0 && existedProduct.quantity > Math.abs(delta)) || delta > 0) {
                existedProduct.quantity += delta
            }
        } else {
            state.draftProducts.push({
                id,
                pickupPointId: state.selectPickupPoint ? state.selectPickupPoint.option.apiId : null,
                quantity: delta > 0 ? delta + 1 : 1,
                price,
                parameters: [],
                discriminated: [],
            })
        }
    },

    [types.CHANGE_DRAFT_DISCRIMINATED_QUANTITY] (state, {
        id, delta, price, discriminator,
    }) {
        const existedProduct = state.draftProducts.find(p => p.id === id)

        if (existedProduct) {
            existedProduct.price = price
            const existedDiscriminated = existedProduct.discriminated.find(
                d => d.discriminator === discriminator,
            )

            if (existedDiscriminated) {
                if ((delta < 0 && existedDiscriminated.quantity > Math.abs(delta)) || delta > 0) {
                    existedDiscriminated.quantity += delta
                }
            } else {
                existedProduct.discriminated.push({
                    discriminator,
                    quantity: delta > 0 ? delta + 1 : 1,
                    parameters: [],
                })
            }
        } else {
            state.draftProducts.push({
                id,
                pickupPointId: state.selectPickupPoint ? state.selectPickupPoint.option.apiId : null,
                quantity: 1,
                price,
                parameters: [],
                discriminated: [{
                    discriminator,
                    quantity: delta > 0 ? delta + 1 : 1,
                    parameters: [],
                }],
            })
        }
    },

    // TODO: код о дискриминаторах нужно упростить, он не читаем
    [types.CHANGE_DRAFT_PRODUCT_PARAMETER] (state, { id, key, value }) {
        const existedProduct = state.draftProducts.find(p => p.id === id)

        if (existedProduct) {
            const existedParameter = existedProduct.parameters.find(o => o.key === key)
            if (existedParameter) {
                existedParameter.value = value
            } else {
                existedProduct.parameters.push({ key, value })
            }
        } else {
            state.draftProducts.push({
                id,
                pickupPointId: state.selectedPickupPoint.option.apiId || null,
                quantity: 1,
                price: 0,
                parameters: [{ key, value }],
                discriminated: [],
            })
        }

        const draft = state.draftProducts.find(p => p.id === id)

        draft.additionalParameters = mapVariantsToParameters(
            filterVariantsByParameters(state.currentVariants, draft.parameters),
        ).filter(parameter => !draft.parameters.map(p => p.key).includes(parameter.key))
    },

    [types.CHANGE_DRAFT_DISCRIMINATED_PARAMETER] (state, {
        id, key, value, discriminator,
    }) {
        const existedProduct = state.draftProducts.find(p => p.id === id)

        if (existedProduct) {
            const existedDiscriminated = existedProduct.discriminated.find(
                d => d.discriminator === discriminator,
            )

            if (existedDiscriminated) {
                const existedParameter = existedDiscriminated.parameters.find(o => o.key === key)

                if (existedParameter) {
                    existedParameter.value = value
                } else {
                    existedDiscriminated.parameters.push({ key, value })
                }
            } else {
                existedProduct.discriminated.push({
                    discriminator,
                    quantity: 1,
                    parameters: [{ key, value }],
                })
            }
        } else {
            state.draftProducts.push({
                id,
                pickupPointId: state.selectPickupPoint ? state.selectPickupPoint.option.apiId : null,
                quantity: 1,
                price: 0,
                parameters: [],
                discriminated: [{
                    discriminator,
                    quantity: 1,
                    parameters: [{ key, value }],
                }],
            })
        }

        const draft = state.draftProducts
            .find(p => p.id === id).discriminated
            .find(d => d.discriminator === discriminator)

        draft.additionalParameters = mapVariantsToParameters(
            filterVariantsByParameters(state.currentVariants, draft.parameters),
        ).filter(parameter => !draft.parameters.map(p => p.key).includes(parameter.key))
    },

    [types.SET_DRAFT] (state, { productId, parameter = [] }) {
        state.draftProduct = [{
            productId,
            pickupPointId: state.selectPickupPoint ? state.selectPickupPoint.option.apiId : null,
            quantity: 1,
            parameters: parameter,
        }]
    },

    [types.SET_PRICES_BY_PICKUP_POINTS] (state, res) {
        state.pricesByPickupPoints = res
    },

    [types.UPDATE_PRICES_IN_BASKET] (state) {
        const selectedPickupPointId = state.selectPickupPoint.option.apiId
        state.basket.forEach((product) => {
            let totalSum = 0
            product.groupModifiers.forEach(groupModifier => {
                groupModifier.modifiers.forEach(modifier => {
                    totalSum += modifier.price
                })
            })
            if (state.pricesByPickupPoints[product.apiId][selectedPickupPointId]) {
                totalSum += state.pricesByPickupPoints[product.apiId][selectedPickupPointId].price
                product.variantTotalSum = totalSum
            } else {
                return
            }
        })
    },

    [types.TOGGLE_IS_PAID_BY_BONUSES] (state, value) {
        state.orderDetails.isPaidByBonuses = value
        
        if (value) {
            const totalBasketSum = state.basket.reduce((acc, curr) => acc + curr.variantTotalSum, 0)
            const canBePayedByBonusesMax = state.bonusSystem.bonusesLimitPerPayment * totalBasketSum / 100
            const bonusesBalance = state.customer.bonuses
            const canBePayedByBonuses = Math.min(canBePayedByBonusesMax, bonusesBalance)
            
            if (totalBasketSum >= canBePayedByBonuses) {
                state.orderDetails.paidByBonuses = String(canBePayedByBonuses)
            } else {
                state.orderDetails.paidByBonuses = totalBasketSum
            }
        } else {
            state.orderDetails.paidByBonuses = ''
        }
    },

    [types.SET_PICKUP_INTERVALS] (state, value) {
        state.pickupIntervals = value

        // костыль для подстановки первого пикап интервала по дефолту
        if (state.orderDetails.delivery.type === 'pickup' && value[0][0]?.date) {
            state.orderDetails.delivery.date = value[0][0].date
            state.orderDetails.delivery.interval = value[0][0]
        }
    },

    [types.RESET_PICKUP_INTERVALS] (state) {
        state.pickupIntervals = []
    },

    // Действия для телеграм пользователя
    [types.SET_TELEGRAM_USER] (state, userData) {
        if (userData) {
            state.customer = {
                ...state.customer,
                ...userData,
                isAuthenticated: true
            }
            console.log(state.customer)
        } else {
            state.authenticatorData.error = 'Ошибка авторизации'
        }
    }
}
