import { PAYMENT_TYPES } from '@/constants'
import yml from '@config/parameters.yml'

const PROMO_CODE_TYPE_ABSOLUTE_VALUE = 'absoluteValue'
const PROMO_CODE_TYPE_PERCENT = 'percent'

// TODO: из-за того что доставка возможно от определенного лимита, если сумма < лимита по умолчанию
// возвращается null и ломается totalSum. т.к. любое число + null = NaN. => нужно починить
export const totalSum = (state) => bonuses =>
    (basketSum(state) + calculateDeliveryPrice(state) - discountOfBasket(state)(bonuses)) > 0
        ? (basketSum(state) + calculateDeliveryPrice(state) - discountOfBasket(state)(bonuses))
        : 0

export const discountOfBasket = state => bonuses => +bonuses + +promoCodeCalculator(state)

export const draftQuantity = state => id => {
    const product = state.draftProducts.find(p => p.id === id)

    return product ? product.quantity : 1
}

export const draftSum = state => (id, defaultPrice) => {
    const product = state.draftProducts.find(p => p.id === id)

    return product ? (product.price * product.quantity) : defaultPrice
}

export const discriminatedDraftQuantity = state => (id, discriminator) => {
    const product = state.draftProducts.find(p => p.id === id)

    if (product) {
        const discriminated = product.discriminated.find(d => d.discriminator === discriminator)

        if (discriminated) {
            return discriminated.quantity
        }
    }

    return 1
}

/**
 * Рассчитывает минимальную стоимость доставки на основе доступных цен на доставку.
 *
 * @param {Object} state - Объект состояния.
 * @returns {number} - Минимальная стоимость доставки.
 */
const minDeliveryPrice = state => {
    if (state.deliveryDetails.deliveryPrices) {
         return Math.min(...Object.keys(state.deliveryDetails.deliveryPrices))
    }

    return 0
}

/**
 * Рассчитывает оставшуюся сумму до минимальной стоимости доставки.
 *
 * @param {Object} state - Объект состояния.
 * @returns {number} - Оставшаяся сумма для до минимальной стоимости доставки.
 */
export const calculateRemainingAmount = state => Math.max(0, minDeliveryPrice(state) - basketSum(state))

/**
 * Рассчитывает стоимость доставки на основе суммы покупок и цен на доставку.
 *
 * @param {Object} state - Объект состояния.
 * @param {Object} deliveryPrices - Доступные цены на доставку.
 * @returns {number|null} - Рассчитанная стоимость доставки или null, если не применимо.
 */
const calculatePrice = (state, deliveryPrices) => {

    if (basketSum(state) <= minDeliveryPrice(state)) {
        return null
    }

    const thresholdForDeliveryPrice = Object.keys(deliveryPrices)
        .sort((a, b) => b - a)
        .find(minPrice => basketSum(state) >= minPrice)

    return deliveryPrices[thresholdForDeliveryPrice]
}

/**
 * Рассчитывает стоимость доставки.
 *
 * @param {Object} state - Объект состояния.
 * @returns {number|null} - Рассчитанная стоимость доставки или null, если не применимо.
 */
export const calculateDeliveryPrice = (state) =>
    state.deliveryDetails.deliveryPrices ? calculatePrice(state, state.deliveryDetails.deliveryPrices) : null

export const calculateBonusesAvailableToUse = (state) => {
    if (state.bonusSystem) {
        return state.bonusSystem.bonusesLimitPerPayment * basketSum(state) / 100
    }
}

export const isNullDeliveryPrices = (state) =>
    state.deliveryDetails.deliveryPrices === null

export const calculateRewardBonuses = (state) => {
    const productsSumValue = basketSum(state)

    if (state.bonusSystem) {
        if (state.orderDetails.isPaidByBonuses) {
            const paidByBonusesSum = state.orderDetails.paidByBonuses
            const payByMoneySum = productsSumValue - paidByBonusesSum

            return Math.round(state.bonusSystem.bonusRewardPercent * payByMoneySum / 100)
        }
        return Math.round(state.bonusSystem.bonusRewardPercent * productsSumValue / 100)
    }
}

export const isCustomerHaveBonuses = (getters) => {
    return getters.clientBonuses > 0
}

export const maxAmountBonusesToUse = (state, getters) => {
    return getters.calculateBonusesAvailableToUse >= getters.clientBonuses
        ? getters.clientBonuses
        : getters.calculateBonusesAvailableToUse
}

export const clientBonuses = state => {
    return state.customer.bonuses
}

export const promoCodeCalculator = (state) => {
    const basketSumValue = basketSum(state)
    if (state.promoCode) {
        switch (state.promoCode.discountType) {
            case PROMO_CODE_TYPE_PERCENT:
                return basketSumValue * state.promoCode.discount / 100
            case PROMO_CODE_TYPE_ABSOLUTE_VALUE:
                return state.promoCode.discount
            default:
                return null
        }
    }

    return 0
}

export const discriminatedDraftTotalSum = state => (id, defaultPrice, discriminator) => {
    const product = state.draftProducts.find(p => p.id === id)
    const discriminated = product
    && product.discriminated.find(d => d.discriminator === discriminator)

    return discriminated
        ? discriminated.quantity * product.price
        : defaultPrice
}

export const availableParameters = state => (productId, discriminator) => {
    /* eslint-disable no-nested-ternary */
    const availableParametersLoc = state.availableParameters
        .map(parameter => ({ // map all parameter to sorted parameters
            key: parameter.key,
            values: parameter.values
                .filter(p => String(p) === '0' || !!p) // remove all falsy values except '0' and 0
                .sort((a, b) => ((Number.isNaN(a) || Number.isNaN(b))
                    ? (a > b ? 1 : -1)
                    : (Number(a) > Number(b) ? 1 : -1))),
        }))

    const draft = state.draftProducts.find(p => p.id === productId)

    const additionalParameters = draft
        ? discriminator
            ? (
                draft.discriminated.find(d => d.discriminator === discriminator)
                || { additionalParameters: [] }
            ).additionalParameters || []
            : (draft || { additionalParameters: [] }).additionalParameters || []
        : []
    /* eslint-enable no-nested-ternary */

    return [
        ...availableParametersLoc
            .filter(parameter => !additionalParameters.map(p => p.key).includes(parameter.key)),
        ...additionalParameters,
    ]
}

/* BASKET LOGIC start */
export const idListFromBasketWithoutModifiers = state => state.basket.map(
    v => (v.modifiersIdList.length === 0 ? +v.id : null),
)

// eslint-disable-next-line max-len
export const quantityOfProductVariant = state => variantId =>
    state.basket.find(v => v.productId === variantId.toString())?.quantity ?? 0

export const findVariantInBasket = state => variant => {
    // поиск варианта в корзине по списку id модификаторов и id варианта
    // eslint-disable-next-line max-len
    return state.basket.find(item => JSON.stringify(item.modifiersIdList) === JSON.stringify(variant.modifiersIdList)
            && item.id === variant.id)
}

export const basketSum = state => state.basket.reduce(
    (acc, item) => acc + (item.variantTotalSum * item.quantity), 0)

export const basketTotalQuantity = state => state.basket.reduce((acc, item) => acc + item.quantity, 0)
/* BASKET LOGIC end */

export const isCourierPayment = state =>(
    state.customer.paymentType === PAYMENT_TYPES.COURIER_CASH
    || state.customer.paymentType === PAYMENT_TYPES.COURIER_CARD
)

export const getPrice = state => id => {
    // ищем в стейте текущий продукт по id
    const currentProduct = state.initProducts.find(product => product.id === id)

    // если продукт не найден или у него нет вариантов - возвращаем null
    if (!currentProduct || !currentProduct.variants) {
        return null
    }

    const getMinPriceVariant = variants => {
        // находим стоимость самого дешевого варианта продукта для отображения цены "от"
        return variants.reduce((acc, curr) => (curr.price < acc.price ? curr : acc), variants[0])
    }

    // ценник "от", если есть несколько вариантов, если вариант один - отобразить просто цену варианта
    const priceFrom = variants.length > 1 ? 'От' : ''
    
    const getPriceWithSelectedPickupPoint = (state, minPriceVariant) => {
        // цена варианта выбранного продукта с наименьшей ценой на выбранной точке
        const pickupPointPrice = state.pricesByPickupPoints[minPriceVariant.apiId]?.[state.selectPickupPoint.option.apiId]?.price
        // если точка выбрана и в разных точках разные цены - отображаем минимальную цену на точке, если не выбрана - минимальную цену варианта
        if (pickupPointPrice) {
            return `${priceFrom} ${pickupPointPrice} ₽`
        }
        return `${priceFrom} ${minPriceVariant.price} ₽`
    }
    
    const getMinPriceFromPickupPoints = (state, minPriceVariant) => {
        const prices = state.pricesByPickupPoints[minPriceVariant.apiId]
        // если в разных точках разные цены, но точка не выбрана - находим вариант с минимальной стоимостью для отображения стоимости "от"
        if (prices) {
            const minPriceFromPickupPoints = Object.values(prices).reduce((acc, curr) => (curr.price < acc.price ? curr : acc), prices[Object.keys(prices)[0]]).price
            return `От ${minPriceFromPickupPoints} ₽`
        }
        return null
    }

    const minPriceVariant = getMinPriceVariant(currentProduct.variants)

    // проверки нужны для того, чтобы не было попыток расчета цены до получения данных с сервера
    if (state.pricesByPickupPoints) {
        if (state.selectPickupPoint) {
            return getPriceWithSelectedPickupPoint(state, minPriceVariant, currentProduct.variants)
        } else {
            return getMinPriceFromPickupPoints(state, minPriceVariant)
        }
    }

    return null
}

export const getVariantPrice = state => (variant) => {
    if (!state.selectPickupPoint) {
        return variant.price
    } else {
        if (!state.pricesByPickupPoints[variant.apiId] || !state.pricesByPickupPoints[variant.apiId][state.selectPickupPoint.option.apiId]) {
            return variant.price
        }
        if (Object.keys(state.pricesByPickupPoints).length > 0) {
            return state.pricesByPickupPoints[variant.apiId][state.selectPickupPoint.option.apiId].price
        }
    }
}

export const getBonusesBalance = state => () => {
    return state.customer.bonuses ?? 0
}

export const getPickupPointPhoneNumber = state => () => {
    return state.orderDetails?.delivery?.pickup?.phone
        ? state.orderDetails.delivery.pickup.phone
        : yml.parameters['app.phone']
}
