<template></template>
<script>
export default {
  name: 'AppTelegramButton',
  props: {
    buttonColor: {
      type: String,
      default: '#F16522'
    },
    buttonTextColor: {
      type: String,
      default: '#ffffff'
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
  },
  emits: ['click'],
  watch: {
    isDisabled(newVal) {
      if (window.Telegram.WebApp.MainButton) {
        if (newVal) {
          window.Telegram.WebApp.MainButton.hide()
        } else {
          window.Telegram.WebApp.MainButton.show()
        }
      }
    }
  },
  mounted() {
    const buttonText = this.$slots.default
        ? this.$slots.default[0].text
        : 'Оформить'

    if (window.Telegram.WebApp.MainButton) {
      window.Telegram.WebApp.MainButton.setText(buttonText)
      window.Telegram.WebApp.MainButton.setParams({
        color: this.buttonColor,
        text_color: this.buttonTextColor
      })
      window.Telegram.WebApp.MainButton.show()

      if (this.isDisabled) {
        window.Telegram.WebApp.MainButton.hide()
      } else {
        window.Telegram.WebApp.MainButton.show()
      }

      window.Telegram.WebApp.MainButton.onClick(this.handleClick)
    }
  },
  methods: {
    handleClick() {
      this.$emit('click')
    }
  },
  beforeDestroy() {
    if (window.Telegram.WebApp.MainButton) {
      window.Telegram.WebApp.MainButton.offClick(this.handleClick)
      window.Telegram.WebApp.MainButton.hide()
    }
  }
}
</script>
