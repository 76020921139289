<template>
  <init-modal
    class="product-modal"
    :is-show="isShow"
    :is-bottom-position="screenWidth <= SCREENS.iPadMax"
    @close="closeModal"
  >
    <div class="product-modal__content">
      <app-product-slider :images="productImages" :need-reset-component="isShow" />
      <div class="product-modal__body">
        <div class="product-modal__body-product-desc">
          <h2 class="product-modal__body-title">
            {{ modalTitle }}
          </h2>
          <p class="product-modal__body-desc">
            {{ productBody || productDescription }}
          </p>
        </div>
        <div v-if="productVariantsListLength > 0 && multipleOptionsList > 1" class="tapes-list">
          <app-radio-tape
            v-for="(radioData, key) in productVariantsList"
            :radio-group="key"
            :key="key"
            :radio-data="radioData"
            @change="activateToggleProduct(key, $event, productId)"
          />
        </div>
        <div
          v-if="productBuyable"
          class="product-modal__accordions"
        >
          <app-accordion
            v-for="(groupModifier, index) in productVariants[activePriceIndex].groupModifiers"
            :key="index"
            :title="groupModifier.title"
          >
            <template #body>
              <template v-if="groupModifier.maxAmount === 1">
                <app-radio
                  v-for="radioModifier in groupModifier.modifiers"
                  :key="radioModifier.id"
                  :radio-data="{
                    groupName: groupModifier.title,
                    value: radioModifier.id,
                    label: radioModifier.productName,
                    isDefaultChecked: false,
                  }"
                  @change="selectRadioModifiersId($event, groupModifier.title)"
                />
              </template>
              <template v-if="groupModifier.maxAmount > 1">
                <app-checkbox
                  v-for="modifier in groupModifier.modifiers"
                  :key="modifier.id"
                  :label="`${modifier.productName} ${modifier.price} ₽`"
                  @change="selectCheckboxModifiersId(modifier.id)"
                />
              </template>
            </template>
          </app-accordion>
        </div>
        <template v-if="isUsingShopLogic">
          <app-button
            v-if="productBuyable && (amountProduct !== 0)"
            @click="addToBasket(productId)"
          >
            Добавить в корзину за {{ resultSum }} ₽
          </app-button>
          <app-button
            v-else
            is-disabled
          >
            Нет в наличии
          </app-button>
        </template>
      </div>
    </div>
  </init-modal>
</template>

<script>
import store from '@store'
import { mapMutations, mapState } from 'vuex'
import * as actions from '@store/types/actions-types'
import * as mutations from '@store/types/mutations-types'

import appRadio from '@ui/app-radio/appRadio.vue'
import appButton from '@ui/app-button/appButton.vue'
import appAccordion from '@components/appAccordion.vue'
import appCheckbox from '@ui/app-checkbox/appCheckbox.vue'
import appRadioTape from '@ui/app-radio-tape/appRadioTape.vue'
import initModal from '@ui/app-modals/init-modal/initModal.vue'
import AppProductSlider from '@ui/app-product-slider/appProductSlider.vue'

export default {
  name: 'ProductModal',
  components: {
    appRadio,
    initModal,
    appButton,
    appCheckbox,
    appAccordion,
    appRadioTape,
    AppProductSlider,
  },
  props: {
    isShow: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  emits: ['close'],
  data: () => ({
    currentSlideIndex: 0,
    image: [],
    checkboxModifiersIdList: [],
    radioModifiersIdList: {},
    resultModifiersIdList: [],
    selectedModifiersSum: 0,
    resetModifiers: false,
  }),
  computed: {
    ...mapState({
      isUsingShopLogic: state => state.isUsingShopLogic,
      activePriceIndex: state => state.activePriceIndex,
      productId: state => state.product.id,
      productTitle: state => state.product.title,
      productBody: state => state.product.body,
      productDescription: state => state.product.description,
      productImages: state => state.product.images,
      productBuyable: state => state.product.isBuyable,
      productVariants: state => state.product.variants,
      productVariantsList: state => state.productVariantsList,
      amountProduct: state => state.amountProduct,
      screenWidth: state => state.screenWidth,
      SCREENS: state => state.screenSizeList,
      selectedParametersIndexMap: state => state.selectedParametersIndexMap,
      selectedPickupPoint: state => state.selectPickupPoint,
      pricesByPickupPoints: state => state.pricesByPickupPoints,
      initProducts: state => state.initProducts,
    }),
    resultSum() {
      if (this.selectedPickupPoint && this.initProducts[this.productId - 1] && this.pricesByPickupPoints) {
        if (!this.pricesByPickupPoints[this.productId - 1][this.selectedPickupPoint.option.apiId]) {
          return this.productVariants[this.activePriceIndex].price + this.selectedModifiersSum
        }
        const variantId = this.initProducts[this.productId - 1].variants[this.activePriceIndex].apiId
        return this.pricesByPickupPoints[variantId][this.selectedPickupPoint.option.apiId].price + this.selectedModifiersSum
      }

      if (!this.selectedPickupPoint) {
        return this.productVariants[this.activePriceIndex].price + this.selectedModifiersSum
      }
    },
    modalTitle() {
      const variants = Object.values(this.productVariantsList)
      const isSingleVariantOfRadioTape = variants.length === 1 && variants[0].size === 1

      if (!isSingleVariantOfRadioTape) {
        return this.productTitle
      }
      if (isSingleVariantOfRadioTape) {
        const singleRadioList = Object.values(this.productVariantsList)[0]
        const singleRadioName = [...singleRadioList][0]

        return `${this.productTitle} ${singleRadioName && `(${singleRadioName})`}`
      }
    },
    productVariantsListLength() {
      return Object.keys(this.productVariantsList).length
    },
    multipleOptionsList() {
      return Object.values(this.productVariantsList)[0].size
    },
  },
  watch: {
    resultModifiersIdList() {
      const activeVariant = this.productVariants[this.activePriceIndex]

      if (activeVariant && activeVariant.groupModifiers) {
        const selectedModifiersList = activeVariant.groupModifiers.flatMap(
            groupModifier => groupModifier.modifiers.filter(
                modifier => this.resultModifiersIdList.includes(modifier.id),
            ),
        )
        this.selectedModifiersSum = selectedModifiersList.reduce((acc, item) => acc + item.price, 0)

        return this.selectedModifiersSum
      }
      return []
    },
  },
  methods: {
    ...mapMutations({
      setActiveToggle: mutations.SET_ACTIVE_TOGGLE,
      changeToggleParameter: mutations.CHANGE_TOGGLE_PARAMETER,
      selectGroupModifier: mutations.SELECT_GROUP_MODIFIER,
      resetVariantIndex: mutations.RESET_ACTIVE_PRICE_VARIANT,
      clearDraftProduct: mutations.CLEAR_DRAFT_PRODUCT,
      clearProduct: mutations.CLEAR_PRODUCT,
      clearProductVariantsList: mutations.CLEAR_PRODUCT_VARIANTS_LIST,
      resetImgPath: mutations.SET_IMAGE_PATH,
      resetImagePosition: mutations.RESET_IMAGE_POSITION,
    }),
    selectCheckboxModifiersId(id) {
      const index = this.checkboxModifiersIdList.indexOf(id)

      index > -1
        ? this.checkboxModifiersIdList.splice(index, 1)
        : this.checkboxModifiersIdList.push(id)

      this.changeResultModifiersArray()
    },
    selectRadioModifiersId(id, key) {
      this.radioModifiersIdList[`${key}Id`] = +id

      this.changeResultModifiersArray()
    },
    changeResultModifiersArray() {
      const radioListValues = Object.values(this.radioModifiersIdList)
      this.resultModifiersIdList = [...this.checkboxModifiersIdList, ...radioListValues]
    },
    resetModalState() {
      this.currentSlideIndex = 0
      this.image = []
      this.checkboxModifiersIdList = []
      this.radioModifiersIdList = {}
      this.resultModifiersIdList = []
      this.selectedModifiersSum = 0
      this.resetVariantIndex()
      this.clearDraftProduct()
      this.resetImgPath('')
      this.resetImagePosition()
      this.clearProduct()
      this.clearProductVariantsList()
    },
    closeModal() {
      this.$emit('close')
      this.resetModalState()
    },
    activateToggleProduct(key, event, productId) {
      this.setActiveToggle([key, event.index])
      this.changeToggleParameter(productId)
      this.$store.commit(mutations.RESET_SELECTED_MODIFIERS)
    },
    addToBasket(productId) {
      store.dispatch(
        actions.ADD_TO_BASKET,
        { currentProductId: productId, currentModifierIds: this.resultModifiersIdList },
      )
      this.closeModal()
    },
  },
}
</script>
